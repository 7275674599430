// src/components/ProtectedRoute.js
import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../app/auth";

const ProtectedRoute = ({ component: Component, allowedRoles, ...rest }) => {
  const { user } = useAuth();
  const location = useLocation();

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  const userRole = user.collectionName; // Assuming collectionName is used to determine the role

  if (allowedRoles.includes(userRole)) {
    return <Component {...rest} />;
  }

  return <Navigate to="/app/transactions" />;
};

export default ProtectedRoute;
