// src/App.js
import React, { lazy, useEffect } from "react";
import "./App.css";
import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { themeChange } from "theme-change";
import { useAuth } from "./app/auth";
import ProtectedRoute from "./components/ProtectedRoute";

// Importing pages
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const EmployeeLogin = lazy(() => import("./pages/EmployeeLogin"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Register = lazy(() => import("./pages/Register"));
const Documentation = lazy(() => import("./pages/Documentation"));
const Verified = lazy(() => import("./pages/protected/Verified"));
const Transactions = lazy(() => import("./pages/protected/Transactions"));
const Calendar = lazy(() => import("./pages/protected/Calendar"));
const Dashboard = lazy(() => import("./pages/protected/Dashboard"));

function App() {
  const { user } = useAuth();

  useEffect(() => {
    themeChange({
      theme: localStorage.getItem("theme") || "light",
    });
    if (localStorage.getItem("theme") !== "light") {
      localStorage.setItem("theme", "light");
    }
    localStorage.setItem("theme", "light");
    if (
      user?.verified === false &&
      window.location.pathname !== "/#app/verification"
    ) {
      window.location.href = "/#app/verification";
    }
  }, [user]);

  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <Navigate to={user ? "/app/transactions" : "/login"} replace />
            }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/employee/login" element={<EmployeeLogin />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          {/* Place new routes over this */}
          <Route path="/app/*" element={<Layout />}>
            <Route
              path="transactions"
              element={
                <ProtectedRoute
                  component={Transactions}
                  allowedRoles={["admins", "employees"]}
                />
              }
            />
            <Route
              path="calendar"
              element={
                <ProtectedRoute
                  component={Calendar}
                  allowedRoles={["admins", "employees"]}
                />
              }
            />
            <Route
              path="dashboard"
              element={
                <ProtectedRoute
                  component={Dashboard}
                  allowedRoles={["admins"]}
                />
              }
            />
            <Route
              path="*"
              element={
                <Navigate to={user ? "/app/transactions" : "/login"} replace />
              }
            />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
